import React from "react"

const StatusPage = () => {
        return (
        <>
            <p>Site is up and running!</p>
        </>
    )
}

export default StatusPage

